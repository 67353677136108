<template>
<div>
  <data-preparation-overlay></data-preparation-overlay>
</div>
</template>

<script>
import JWTToken from "@/common/jwt.service";
import { SET_AUTH } from "@/store/mutations.type";
import AuthApiService from "@/common/api/auth.service";
import BaseApiService from "@/common/api/base.service";
import DataPreparationOverlay from '@/views/components/DataPreparationOverlay.vue';

export default {
  components: { DataPreparationOverlay },
  name: "HybridOrderModuleAccess",
  data: function() {
      return {
          isLoading: true,
      };
  },
  created() {
    let storeName = this.$route.query.store;
    let token = this.$route.query.data;
    let appId = this.$route.query.app_id;
    if (token === undefined && appId === undefined){
      alert("access denied")
    }
    // 1. try to login to backend
    this.isLoading = true;
    JWTToken.saveToken(token);
    BaseApiService.setHeader();
    AuthApiService.accessHybridOrderModule(storeName, "shopify", appId)
        .then(r => {
          if (r.data.success) {
            this.$store.commit(`auth/${SET_AUTH}`, r);
            this.$router.push({name: "OrderManagementPage"});
          } else {
            alert('access failed!')
            console.log(r.data);
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          alert('access failed!')
          this.isLoading = false;
        });
  },
};
</script>